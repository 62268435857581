<template>
  <div>
    <b-card noBody>
      <!-- input search -->
      <h5>Tìm Kiếm:</h5>
      <div class="d-flex">
        <b-form-input
          style="width: 30%"
          type="search"
          v-model="searchText"
          @input="onInput"
          placeholder="Nhập id hoặc email người dùng..."
        ></b-form-input>

        <b-form-select
          @change="handleChangeSelect"
          class="ml-4"
          style="width: 20%"
          v-model="selected"
          :options="options"
        ></b-form-select>

        <FormAddUser :reloadUserList="reloadUserList" />
      </div>

      <!-- danh sách user -->
      <b-tabs pills card>
        <FormEditUser v-bind:userInfo="userinfo" v-on:editUser="editUser" />

        <b-table
          striped
          hover
          id="my-table"
          :items="getUserList"
          :fields="fields"
          :per-page="perPage"
        >
          <template v-slot:head(checkbox)>
            <span>
              <b-form-checkbox
                v-model="checkAllPro"
                size="lg"
                @change="checkAll"
              ></b-form-checkbox>
            </span>
          </template>

          <template v-slot:cell(checkbox)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.checkbox"
                @change="clickIndex2(row)"
              ></b-form-checkbox>
            </div>
          </template>

          <template v-slot:cell(deleted)="row">
            <div v-if="row.item.deleted === false">còn hoạt động</div>
            <div v-else>đã xóa</div>
          </template>

          <template v-slot:cell(departmentId)="row">
            <div>{{ row.item.department.departmentName }}</div>
          </template>

          <template v-slot:cell(action)="row">
            <div class="d-flex">
              <!-- btn sửa -->
              <div class="cursor_pointer mr-5">
                <i
                  class="fa fa-user-edit text-info"
                  v-b-modal:[`edit-user-${row.item.id}`]
                  v-on:click="handleEdit(row)"
                ></i>
              </div>

              <!--  btn xóa -->
              <div class="cursor_pointer" v-on:click="handleConfirmDelete(row)">
                <i class="fa fa-trash text-danger"></i>
              </div>
            </div>
          </template>
        </b-table>
        <!--//? phan trang -->
        <b-pagination
          class="justify-content-end"
          @change="handldeChangeCurrentPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import FormEditUser from "@/view/content/popupmodals/FormEditUser";
import ApiService from "@/core/services/api.service.js";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
import FormAddUser from "@/view/content/popupmodals/FormAddUser.vue";
export default {
  data() {
    return {
      fields: [
        {
          key: "checkbox",
          label: "",
          thStyle: { textAlign: "center" },
          tdClass: "checkboxProClass",
          thClass: "checkboxProClass",
        },
        { key: "id", label: "ID" },
        { key: "email", label: "Email" },
        { key: "role", label: "Quyền" },
        { key: "departmentId", label: "Phòng Ban" },
        { key: "deleted", label: "Trạng Thái" },
        { key: "action", label: "Thao Tác" },
      ],
      userinfo: null,
      searchText: "",
      selected: null,
      options: [],
      currentPage: 1,
      checkAllPro: false,
      arrCheckBoxUser: [],
    };
  },
  props: {
    reloadUserList: {
      type: Function,
    },
    arrUser: {
      type: Array,
    },
    totalPages: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    reloadUserListDeleted: {
      type: Function,
    },
    CurrentPageUserListDeleted: {
      type: Number,
    },
  },
  methods: {
    checkAll(value) {
      this.checkAllPro = value;
      if (this.checkAllPro === true) {
        this.arrUser.forEach((item) => {
          item.checkbox = true;
          this.arrCheckBoxUser.push(item);
        });
      } else {
        this.arrUser.forEach((item) => {
          item.checkbox = false;
          this.arrCheckBoxUser = [];
        });
      }
      console.log(this.arrCheckBoxUser);
    },
    clickIndex2(row) {
      const checkbox = row.item.checkbox;
      if (checkbox === true) {
        this.arrCheckBoxUser.push(row.item);
      } else {
        this.arrCheckBoxUser.forEach((item, i) => {
          if (item.checkbox === false) {
            this.arrCheckBoxUser.splice(i, 1);
          }
        });
      }

      if (this.arrCheckBoxUser.length === 0) this.checkAllPro = false;
      console.log(this.arrCheckBoxUser);
    },
    handleDeleted(row) {
      const id = row.item.id;
      ApiService.delete(`deleted-user/${id}`)
        .then(() => {
          this.reloadUserList(this.selected, (this.currentPage = 1));
          this.reloadUserListDeleted(null, this.CurrentPageUserListDeleted);
          successNotification("xóa thành công");
        })
        .catch(() => {
          errorNotification("xóa thất bại");
        });
    },
    handleEdit(row) {
      const newItems = { ...row.item };
      this.userinfo = newItems;
    },
    handleAddUser() {
      this.openModalAdd = !this.openModalAdd;
    },
    editUser(db) {
      const { departmentId, email, role, id } = db;
      let updateUser = {
        departmentId,
        email,
        role,
      };
      ApiService.put(`/update-user/${id}`, updateUser)
        .then(() => {
          this.reloadUserList(this.selected, this.currentPage);
          successNotification("sửa thành công");
        })
        .catch(() => {
          errorNotification("sửa thất bại");
        });
    },
    // ---------- feature search -----------------
    async search() {
      const response = await ApiService.query(`/search-user`, {
        params: {
          id: this.searchText,
          email: this.searchText,
          departmentId: this.selected,
          pageSize: this.perPage,
          deleted: 0,
        },
      });
      this.currentPage = 1;
      const payload = response.data.data;
      this.$emit("searchUserByIdOrEmail", payload);
    },
    onInput: debounce(function () {
      this.search();
    }, 500),

    getDepartmentList() {
      ApiService.get("/department/getList").then((db) => {
        const { data } = db.data;
        const newData = data.map((item) => {
          return {
            value: item.id,
            text: item.departmentName,
          };
        });
        this.options = [{ value: null, text: "Tất cả phòng ban" }, ...newData];
      });
    },
    handldeChangeCurrentPage(value) {
      const payload = {
        departmentId: this.selected,
        page: value,
      };
      this.$emit("changePage", payload);
    },
    handleChangeSelect(value) {
      this.$emit("filterUserByDepartment", value);
    },

    handleConfirmDelete(row) {
      this.$bvModal
        .msgBoxConfirm("Bạn có chắc muốn xóa người dùng này ?", {
          title: "Xác Nhận Xóa",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Có",
          cancelTitle: "Không",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.handleDeleted(row);
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
  },
  computed: {
    getUserList() {
      return this.arrUser;
    },
    rows() {
      return parseInt(this.totalPages);
    },
  },
  watch: {},
  components: {
    FormEditUser,
    FormAddUser,
  },
  mounted() {
    this.getDepartmentList();
  },
};
</script>
<style>
.cursor_pointer {
  cursor: pointer;
}
</style>
