<template>
  <div class="ml-4">
    <b-button
      variant="success"
      id="show-btn"
      @click="$bvModal.show('bv-modal-addUser')"
      >Thêm Người Dùng</b-button
    >

    <b-modal id="bv-modal-addUser" hide-footer>
      <template #modal-title> Form Thêm Người Dùng</template>
      <div class="d-block">
        <b-form v-on:submit.prevent="submit">
          <b-form-group>
            <label for="email">Email</label>
            <b-form-input
              id="email"
              type="email"
              :state="validateState('email')"
              placeholder="nhập email"
              v-model.trim="$v.form.email.$model"
            ></b-form-input>
            <b-form-invalid-feedback>
              Yêu cầu nhập Email
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label for="email">Mật Khẩu</label>
            <b-form-input
              id="password"
              type="password"
              :state="validateState('password')"
              placeholder="nhập mật khẩu"
              v-model.trim="$v.form.password.$model"
            ></b-form-input>
            <b-form-invalid-feedback>
              Yêu Cầu Nhập Nhập Mật Khẩu
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label>Quyền</label>
            <b-form-select
              v-model.trim="$v.form.role.$model"
              :options="optionsRole"
              :state="validateState('role')"
            ></b-form-select>
            <b-form-invalid-feedback> Yêu Chọn Quyền </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label>Phòng Ban</label>
            <b-form-select
              v-model.trim="$v.form.departmentId.$model"
              :options="optionsDepartment"
              :state="validateState('departmentId')"
            ></b-form-select>
            <b-form-invalid-feedback>
              Yêu Chọn Phòng Ban
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </div>
      <b-button class="mt-3" variant="success" block @click="handleAddUser"
        >Thêm</b-button
      >
    </b-modal>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service.js";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
export default {
  mixins: [validationMixin],
  data() {
    return {
      optionsRole: [
        { value: null, text: "Vui lòng chọn quyền" },
        { value: "user", text: "user" },
        { value: "admin", text: "admin" },
      ],
      optionsDepartment: [],
      form: {
        email: "",
        role: null,
        departmentId: null,
        password: "",
      },
    };
  },
  props: {
    reloadUserList: {
      type: Function,
    },
  },
  validations: {
    form: {
      email: { required, email },
      role: { required },
      departmentId: { required },
      password: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    handleAddUser() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const { departmentId, email, role, password } = this.$v.form.$model;
      const payload = {
        email,
        departmentId,
        role,
        password,
      };
      ApiService.post("/addUser", payload)
        .then(() => {
          successNotification("Thêm Thành Công");
          this.$v.form.$reset();
          this.form = {
            email: "",
            role: null,
            departmentId: null,
            password: "",
          };
          this.reloadUserList();
          this.hideModal();
        })
        .catch(() => {
          errorNotification("Thêm Thất Bại");
        });
    },
    hideModal() {
      this.$bvModal.hide("bv-modal-addUser");
    },
    getDepartmentList() {
      ApiService.get("/department/getList").then((db) => {
        const { data } = db.data;
        const newData = data.map((item) => {
          return {
            value: item.id,
            text: item.departmentName,
          };
        });
        const payload = [
          { value: null, text: "Vui lòng chọn phòng ban" },
          ...newData,
        ];
        this.optionsDepartment = payload;
      });
    },
  },
  mounted() {
    this.getDepartmentList();
  },
};
</script>
<style></style>
