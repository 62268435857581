<template>
  <div>
    <b-card noBody>
      <h5>Tìm Kiếm:</h5>
      <div class="d-flex">
        <!-- //? input search -->
        <b-form-input
          style="width: 30%"
          type="search"
          v-model="searchText"
          @input="onInput"
          placeholder="Nhập id hoặc email người dùng..."
        ></b-form-input>

        <!-- //? select department -->
        <b-form-select
          @change="handleChangeSelect"
          class="ml-4"
          style="width: 20%"
          v-model="selected"
          :options="options"
        ></b-form-select>
      </div>

      <b-tabs pills card>
        <!-- //? bảng danh sách người dùng bị xóa -->
        <b-table
          striped
          hover
          :items="getUserListDeleted"
          :fields="fields"
          :per-page="perPage"
        >
          <template v-slot:cell(deleted)="row">
            <div v-if="row.item.deleted === false">còn hoạt động</div>
            <div v-else>đã xóa</div>
          </template>

          <template v-slot:cell(departmentId)="row">
            <div>{{ row.item.department.departmentName }}</div>
          </template>

          <template v-slot:cell(action)="row">
            <!-- btn khôi phục -->
            <div
              class="cursor_pointer mr-5"
              v-on:click="handleConfirmRestore(row)"
            >
              <i class="fa fa-sync text-warning"></i>
            </div>
          </template>
        </b-table>
        <!--//? phan trang -->
        <b-pagination
          class="justify-content-end"
          @change="handldeChangeCurrentPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import ApiService from "@/core/services/api.service.js";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
export default {
  data() {
    return {
      fields: [
        { key: "id", label: "ID" },
        { key: "email", label: "Email" },
        { key: "role", label: "Quyền" },
        { key: "departmentId", label: "Phòng Ban" },
        { key: "deleted", label: "Trạng Thái" },
        { key: "action", label: "Thao Tác" },
      ],
      userinfo: null,
      searchText: "",
      selected: null,
      options: [],
      currentPage: 1,
    };
  },
  props: {
    arrUserDeleted: {
      type: Array,
    },
    reloadUserListDeleted: {
      type: Function,
    },
    totalPagesDeleted: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    reloadUserList: {
      type: Function,
    },
    CurrentPageUserList: {
      type: Number,
    },
  },
  methods: {
    handleConfirmRestore(row) {
      this.$bvModal
        .msgBoxConfirm("Bạn có chắc muốn khôi phục người dùng này ?", {
          title: "Xác Nhận Khôi Phục",
          size: "sm",
          buttonSize: "sm",
          okVariant: "warning",
          okTitle: "Có",
          cancelTitle: "Không",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.handleRestore(row);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleRestore(row) {
      const id = row.item.id;
      ApiService.put(`restore-user/${id}`)
        .then(() => {
          this.reloadUserListDeleted(this.selected, (this.currentPage = 1));
          this.reloadUserList(null, this.CurrentPageUserList);
          successNotification("khôi thành công");
        })
        .catch(() => {
          errorNotification("khôi phục thất bại");
        });
    },
    handldeChangeCurrentPage(value) {
      const payload = {
        departmentId: this.selected,
        page: value,
      };
      this.$emit("changePageUserDeleted", payload);
    },
    handleChangeSelect(value) {
      this.$emit("filterUserByDepartmentOfListDeleted", value);
    },

    getDepartmentList() {
      ApiService.get("/department/getList").then((db) => {
        const { data } = db.data;
        const newData = data.map((item) => {
          return {
            value: item.id,
            text: item.departmentName,
          };
        });
        this.options = [{ value: null, text: "Tất cả phòng ban" }, ...newData];
      });
    },

    async search() {
      const response = await ApiService.query(`/search-user`, {
        params: {
          id: this.searchText,
          email: this.searchText,
          departmentId: this.selected,
          pageSize: this.perPage,
          deleted: 1,
        },
      });
      const payload = response.data.data;
      this.$emit("searchUserByIdOrEmailDeleted", payload);
    },
    onInput: debounce(function () {
      this.search();
    }, 500),
  },
  computed: {
    getUserListDeleted() {
      return this.arrUserDeleted;
    },
    rows() {
      return parseInt(this.totalPagesDeleted);
    },
  },
  components: {},

  mounted() {
    this.getDepartmentList();
  },
};
</script>
<style>
.cursor_pointer {
  cursor: pointer;
}
</style>
