<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-3">
        <b-tab title="Danh Sách Người Dùng" active>
          <ListOfUserVue
            v-on:changePage="changePage"
            v-on:filterUserByDepartment="filterUserByDepartment"
            v-on:searchUserByIdOrEmail="searchUserByIdOrEmail"
            :reloadUserList="reloadUserList"
            :reloadUserListDeleted="reloadUserListDeleted"
            :arrUser="arrUser"
            :totalPages="totalPages"
            :perPage="perPage"
            :CurrentPageUserListDeleted="CurrentPageUserListDeleted"
          />
        </b-tab>
        <b-tab title="Danh Sách Người Dùng Đã Xóa">
          <ListOfUserDeletedVue
            v-on:filterUserByDepartmentOfListDeleted="
              filterUserByDepartmentOfListDeleted
            "
            v-on:searchUserByIdOrEmailDeleted="searchUserByIdOrEmailDeleted"
            v-on:changePageUserDeleted="changePageUserDeleted"
            :reloadUserListDeleted="reloadUserListDeleted"
            :reloadUserList="reloadUserList"
            :arrUserDeleted="arrUserDeleted"
            :totalPagesDeleted="totalPagesDeleted"
            :perPage="perPage"
            :CurrentPageUserList="CurrentPageUserList"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service.js";
import ListOfUserVue from "@/view/content/admin/ListOfUser.vue";
import ListOfUserDeletedVue from "@/view/content/admin/ListOfUserDeleted.vue";
export default {
  data() {
    return {
      //* data for user list
      arrUser: [],
      totalPages: null,
      perPage: 3,
      CurrentPageUserList: 1,
      //* data for user list deleted
      arrUserDeleted: [],
      totalPagesDeleted: null,
      CurrentPageUserListDeleted: 1,
    };
  },
  methods: {
    reloadUserList(departmentId, page) {
      ApiService.query("/takeAListOfUser", {
        params: {
          departmentId,
          page,
          pageSize: this.perPage,
        },
      }).then((db) => {
        const { data } = db.data.data;
        this.arrUser = data.map((item) => {
          return {
            ...item,
            checkbox: false,
          };
        });
        this.totalPages = db.data.data.count;
      });
    },

    filterUserByDepartment(data) {
      this.reloadUserList(data);
    },
    searchUserByIdOrEmail(data) {
      this.arrUser = data.data;
      this.totalPages = data.count;
    },
    changePage(data) {
      const { departmentId, page } = data;
      this.reloadUserList(departmentId, page);
      this.CurrentPageUserList = page;
    },

    // *--------- user deleted ------------

    reloadUserListDeleted(departmentId, page) {
      ApiService.query("/takeAListOfUserDeleted", {
        params: {
          departmentId,
          page,
          pageSize: this.perPage,
        },
      }).then((db) => {
        const { data } = db.data.data;
        this.arrUserDeleted = data;
        this.totalPagesDeleted = db.data.data.count;
      });
    },
    changePageUserDeleted(data) {
      const { departmentId, page } = data;
      this.reloadUserListDeleted(departmentId, page);
      this.CurrentPageUserListDeleted = page;
    },
    searchUserByIdOrEmailDeleted(db) {
      this.arrUserDeleted = db.data;
      this.totalPagesDeleted = db.count;
    },
    filterUserByDepartmentOfListDeleted(data) {
      this.reloadUserListDeleted(data);
    },
  },
  mounted() {
    this.reloadUserList();
    this.reloadUserListDeleted();
  },
  components: {
    ListOfUserVue,
    ListOfUserDeletedVue,
  },
};
</script>
<style></style>
