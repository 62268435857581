<template>
  <div>
    <b-modal
      v-if="userInfo !== null"
      :id="`edit-user-${userInfo?.id}`"
      hide-footer
    >
      <template #modal-title> Form Sửa Thông Tin Người Dùng </template>
      <div class="d-block">
        <b-form v-on:submit.prevent="submit">
          <b-form-group>
            <label for="email">Email</label>
            <b-form-input
              id="email"
              type="email"
              :state="validateState('email')"
              placeholder="nhập email"
              v-model.trim="$v.form.email.$model"
            ></b-form-input>
            <b-form-invalid-feedback>
              Yêu cầu nhập Email
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label>Quyền</label>
            <b-form-select
              v-model.trim="$v.form.role.$model"
              :options="optionsRole"
              :state="validateState('role')"
            ></b-form-select>
            <b-form-invalid-feedback> Yêu Chọn Quyền </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label>Phòng Ban</label>
            <b-form-select
              v-model.trim="$v.form.departmentId.$model"
              :options="optionsDepartment"
              :state="validateState('departmentId')"
            ></b-form-select>
            <b-form-invalid-feedback>
              Yêu Chọn Phòng Ban
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </div>

      <div class="d-flex">
        <b-button class="mt-3" variant="success" block v-on:click="editUser"
          >Cập Nhật</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "modals-admin",
  data() {
    return {
      optionsRole: [
        { value: null, text: "Vui lòng chọn quyền" },
        { value: "user", text: "user" },
        { value: "admin", text: "admin" },
      ],
      optionsDepartment: [],
      form: {
        email: "",
        role: null,
        departmentId: null,
      },
    };
  },
  validations: {
    form: {
      email: { required },
      role: { required },
      departmentId: { required },
    },
  },
  props: {
    userInfo: {
      type: Object,
    },
  },
  watch: {
    userInfo(newValue) {
      this.form.email = newValue.email;
      this.form.role = newValue.role;
      this.form.departmentId = newValue.departmentId;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    hideModal() {
      this.$bvModal.hide(`edit-user-${this.userInfo?.id}`);
    },
    editUser() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const { departmentId, email, role } = this.$v.form.$model;
      const data = {
        id: this.userInfo.id,
        email,
        departmentId,
        role,
      };
      this.$emit("editUser", data);
      this.hideModal();
    },
    getDepartmentList() {
      ApiService.get("/department/getList").then((db) => {
        const { data } = db.data;
        const newData = data.map((item) => {
          return {
            value: item.id,
            text: item.departmentName,
          };
        });
        this.optionsDepartment = newData;
      });
    },
  },
  computed: {},
  mounted() {
    this.getDepartmentList();
  },
};
</script>
<style></style>
